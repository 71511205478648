@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.mont {
  font-family: 'Montserrat', sans-serif;
}

html {
    ::-webkit-scrollbar {
        width: 2px;
      }
      
      ::-webkit-scrollbar-track {
        background-color: rgb(63, 60, 60);
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: rgb(39, 147, 39);
      }
}

body {
    /* color: #bcb6c5; */
    /* background: #0f0d28; */
    /* background: linear-gradient(159.02deg, #050717 14.25%, #080a1d 56.45%, #020515 86.14%) !important; */
    background-color: black;
    color: white;
  }

/* Custom CSS for the sidebar open and close animation */
.sidebar-closed {
    transform: translateX(-100%);
  }

  .sidebar-open {
    transform: translateX(0);
  }
  
  .sidebar {
    background: #18191c !important;
    z-index: 1000;
    background: linear-gradient(159.02deg, #050717 14.25%, #13141b 56.45%, #080910 86.14%) !important;
    /* background: linear-gradient(90deg, rgba(30,30,38,1) 26%, rgba(0,0,0,1) 100%); */
  }
  
  .active_link {
    background-color: #47cc4e;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px;
  }
  
  .header {
    z-index: 100;
    background: #151516 !important;
    /* background: linear-gradient(159.02deg, #0F123B 14.25%, #090D2E 56.45%, #020515 86.14%) !important; */
  }

  .socials {
    z-index: 100;
  }

  .guess {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(360deg, rgba(240, 194, 89, 0.2) 0%, rgba(240, 194, 89, 0.2) 100%);
  }

  .guesstop {
    background: linear-gradient(273.19deg, #ED6A05 0%, #F0C259 100%, #F0C259 100%);
  }

  .guessper {
    border: 2px solid rgba(240, 194, 89, 0.16);
    background: rgba(240, 194, 89, 0.14);
  }

/* Background images */
.red_blur {
    background-image: url("/public/red-blur.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.cycle1 {
  background: linear-gradient(273.19deg, #ED6A05 0%, #F0C259 100%, #F0C259 100%);
}
.cycle2 {
  background: linear-gradient(273.19deg, #0032BF 0%, #7BB8FF 100%);
}
.cycle3 {
  background: linear-gradient(273.19deg, #87008D 0%, #E67DEB 100%);
}
.cycle4 {
  background: linear-gradient(273.19deg, #3D00A0 0%, #9E70EA 100%);
}
.cycle5 {
  background: linear-gradient(273.19deg, #C62C70 0%, #FF9999 100%);
}

.renew-btn {
  background-color: rgb(255 255 255 / 12%);
}

/* hAM bUTTON */
.wrapper-menu {
  width: 35px;
  height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 330ms ease-out;
}

.wrapper-menu.open {
  transform: rotate(-45deg);  
}

.line-menu {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  height: 4px;
}

.line-menu.half {
  width: 50%;
}

.line-menu.start {
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: right;
}

.open .line-menu.start {
  transform: rotate(-90deg) translateX(3px);
}

.line-menu.end {
  align-self: flex-end;
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: left;
}

.open .line-menu.end {
  transform: rotate(-90deg) translateX(-3px);
}

/* players */
.players {
  background: linear-gradient(273.19deg, #3D00A0 0%, #9E70EA 100%);
}